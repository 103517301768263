import React from "react";
import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import routes from "./Routes";
import TopNavBar from "./components/navbar/NavBar";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Router>
        <TopNavBar />
        <Switch>
          {routes.map(({ exact, path, Component }) => {
            return (
              <Route
                exact={exact}
                path={path}
                key={path}
                component={Component}
              />
            );
          })}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
