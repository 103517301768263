import React from "react";
import "./Navbar.css"
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyringe } from "@fortawesome/free-solid-svg-icons";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function TopNavBar() {
  return (
    <Navbar className="mainNav" expand="lg">
      <Container fluid className="nav-container">
        <Navbar.Brand href="/">
          <FontAwesomeIcon icon={faSyringe}></FontAwesomeIcon>
        </Navbar.Brand>
        <Navbar.Collapse
          className="d-flex flex-row-reverse"
          id="basic-navbar-nav"
        >
          <Nav className="me-auto mb-2 mb-lg-0">
            <Nav.Link className="header-link" href="/about">About</Nav.Link>
            <Nav.Link className="header-link" href="/GlobalVacc">Global Data</Nav.Link>
            <Nav.Link className="header-link" href="/ClinicalTrials">Clinical Trials</Nav.Link>
            <Nav.Link className="header-link" href="/Beliefs">COVID Belief Data</Nav.Link>
            <Nav.Link className="header-link" href="/Vaccines">COVID Vaccine Info</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
};