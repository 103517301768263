import React from "react";
import { useEffect, useState } from "react";
import { thousands_separators } from "./GlobalVacc";

const CountryInstance = ({match}) => {
    const [country, setCountry] = useState([]);
    // fetch country data and render on callback
    useEffect(() => {
        const url = "https://vaxtracker.me/api/countries?countryId=" + match.params.id;
        fetchJSON(url).then((data) => {
        setCountry(data);
        });
    }, []);
    // generic async JSON fetching code
    async function fetchJSON(url) {
        var res = await fetch(url);
        return res.json();
    }
    return (
        <div>
            <h1>{country.name}</h1>
            <p>
                Capital: {country.capital}
            </p>
            <img src={country.flag}></img>
            <div className='row justify-content-center'>
            <div className='col-sm-4'>
                <p class="card-text">Population: {thousands_separators(country.population)}</p>
                <p class="card-text">Total Vaccinations: {thousands_separators(country.total_doses_admin)}</p>
                <p class="card-text">Total People Vaccinated: {thousands_separators(country.total_people_vacc)}</p>
                <p class="card-text">Percentage of Population Vaccinated: {parseFloat(country.percent_pop_vacc).toFixed(2)}%</p>
            </div>
            </div>
            {/* <img src={usa_vac1} width="800" height="400"></img> */}
            <br></br>
            {/* <a href="/AmericaBeliefs" className="btn btn-primary">See beliefs about COVID-19 in Related Country</a> */}
            <p></p>
        </div>
    )
}
export default CountryInstance;