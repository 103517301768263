import React from 'react';
import "./Beliefs.css";

const Beliefs = () => {
  return (
    <div className="App">
      <h1>Beliefs</h1>
    </div>
  )
}

export default Beliefs;