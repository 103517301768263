import React from "react"

import Home from "./pages/home/Home";
import About from "./pages/about/About";
import ClinicalTrials from "./pages/clinical-trials/ClinicalTrials";
import GlobalVacc from "./pages/countries/GlobalVacc";
import Beliefs from "./pages/beliefs/Beliefs";
import Vaccines from "./pages/vaccines/Vaccines"
import CountryInstance from "./pages/countries/CountryInstance";
import VaccinesInstance from "./pages/vaccines/VaccinesInstance"


const Routes = [
    {
        title: "Home",
        path: "/",
        exact: true,
        Component: Home
    },
    {
        title: "About",
        path: "/about",
        exact: true,
        Component: About
    },
    {
        title: "Clinical Trials",
        path: "/clinicaltrials",
        exact: true,
        Component: ClinicalTrials
    },
    {
        title: "Global Vaccinations",
        path: "/globalvacc",
        exact: true,
        Component: GlobalVacc
    },
    {
        path: "/globalvacc/:id",
        Component: CountryInstance
    },
    {
        title: "Beliefs",
        path: "/beliefs",
        exact: true,
        Component: Beliefs
    },
    {
        title: "Vaccines",
        path: "/vaccines",
        exact: true,
        Component: Vaccines
    }
]

export default Routes;