import React, { Component, forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from "@material-ui/icons";
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

export const clinical_trial_data = [

];

const columns = [
    {
        title: "Title",
        render: rowData => <a href={'/ClinicalTrials/' + rowData.trial_id}>{rowData.title}</a>,
        field: "title"
    },
    {
        title: "Status",
        field: "status"
    },
    {
        title: "Study Type",
        field: "study_type"
    },
    {
        title: "Study Phase",
        field: "study_phase"
    },
    {
        title: "Start Date",
        field: "start_date"
    },
    {
        title: "Completion Date",
        field: "completion_date"
    },
    {
        title: "Purpose",
        field: "purpose"
    },
    {
        title: "Enrollment",
        field: "enrollment"
    },
    {
        title: "Country",
        field: "country"
    }
];

// class ClinicalTrials extends React.Component {
//     render() {
//         return (
//             <MaterialTable icons={tableIcons} title="Clinical Trials On COVID-19" data={clinical_trial_data} columns={columns} />
//         );
//     }
// }
function ClinicalTrials() {
    var [trials, setTrials] = useState([]);
  
    // fetch country data and render on callback
    useEffect(() => {
      const url = "https://vaxtracker.me/api/trials";
      fetchJSON(url).then((data) => {
        setTrials(data);
      });
    }, []);
  
    // generic async JSON fetching code
    async function fetchJSON(url) {
      var res = await fetch(url);
      return res.json();
    }
  
    return (
        <MaterialTable icons={tableIcons} title="Clinical Trials On COVID-19" data={trials} columns={columns} />
    );
  }
export default ClinicalTrials;