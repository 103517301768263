import anjitha from "../pages/about/anjitha.png"
import evan from "../pages/about/evan.jpeg"
import lilia from "../pages/about/lilia.jpeg"
import mingkang from "../pages/about/mingkang.jpeg"
import seth from "../pages/about/seth.png"
export const memberInfo = [
  {
      name: ["Anjitha Nair"],
      desc: "desc",
      jobs: "jobs",
      image: anjitha,
      gitlab: "anjithaknair",
      commits: 0,
      issues: 0,
      tests: 0,
      email: "anjithaknair10@gmail.com"
  },
  {
      name: ["Evan Seils"],
      desc: "desc",
      jobs: "jobs",
      image: evan,
      gitlab: "archevan",
      commits: 0,
      issues: 0,
      tests: 0,
      email: "evanseils2013@gmail.com"
  },
  {
      name: ["Lilia Li"],
      desc: "desc",
      jobs: "jobs",
      image: lilia,
      gitlab: "liliali2000",
      commits: 0,
      issues: 0,
      tests: 0,
      email: "liliali2000@utexas.edu"
  },
  {
      name: ["Mingkang Zhu"],
      desc: "desc",
      jobs: "jobs",
      image: mingkang,
      gitlab: "mkzhu2000",
      commits: 0,
      issues: 0,
      tests: 0,
      email: "mkzhu2000@gmail.com"
  },
  {
      name: ["Seth Erfurt"],
      desc: "desc",
      jobs: "jobs",
      image: seth,
      gitlab: "Subject38",
      commits: 0,
      issues: 0,
      tests: 0,
      email: "seth@ske.moe"
  }
]
