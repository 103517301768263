import React from 'react'
import "./InfoCard.css"

const InfoCards = ({ members }) => {
  return (
    <div>
        <div class="row">
          <div className='col-sm-4'>
            <div class='card'style={{width: "13rem", height: "30rem", margin: "0.3rem 0.3rem 0.3rem 0.3rem" }}>
            <img className="card-img-top" src={members.image}></img>
              <div class="card-body">
                <h5 class="card-title">{members.name}</h5>
                <p class="card-text">Bio: {members.desc}</p>
                <p class="card-text">Role: {members.jobs}</p>
                <p class="card-text">Commits: {members.commits}</p>
                <p class="card-text">Issues: {members.issues}</p>
              </div>
            </div>
          </div>
        </div>
    </div >
  )
};

export default InfoCards