import React from "react";
import Country_Card from "./Country_Card"
import { Grid} from "@material-ui/core";

function Country_Grid({data}) {

    return (
      <Grid container spacing={3}
        justify="center"
      >
        {data.map((col) => {
          return (
          <Grid item xs={3}>
            <Country_Card data={col}/>
          </Grid>

          );
        })}

      </Grid>
    );
}
export default Country_Grid;
