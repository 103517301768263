import React from "react";
import "./PostmanButton.css";

// Simple centered button to link to Postman API docs
function PostmanButton() {
  return (
    <div className="container-fluid postman-card">
      <div className="row justify-content-center">
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Vaxtracker API Documentation</h5>
              <a
                href="https://documenter.getpostman.com/view/14740859/TzCHAqDu"
                target="_blank"
                className="btn btn-primary"
              >
                View on Postman
              </a>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default PostmanButton;
