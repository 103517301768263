import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Container, Row } from "react-bootstrap";
import { faHatCowboySide, faDatabase, faViruses } from "@fortawesome/free-solid-svg-icons";
import "./ModalList.css"

const ModalList = () => {
  return (
    <Container fluid className="modal-list">
      <Row className="justify-content-md-center">
        <Card className="wcard mr-5" style={{ width: "22rem" }}>
          <Card.Body>
            <FontAwesomeIcon className="modal-icon" icon={faDatabase} />
            <Card.Text>
                Utilizes data from multiple sources to provide the most up-to-date information
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="wcard ml-5 mr-5" style={{ width: "22rem" }}>
          <Card.Body>
            <FontAwesomeIcon className="modal-icon" icon={faHatCowboySide} />
            <Card.Text>
                Designed and developed by students from the University of Texas Computer Science Department with love 💖!
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="wcard ml-5" style={{ width: "22rem" }}>
          <Card.Body>
            <FontAwesomeIcon className="modal-icon" icon={faViruses} />
            <Card.Text>
                Easily compare vaccine availability and administration data on both a local and global scale.
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default ModalList;
