import React, { Component, forwardRef } from "react";
import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn} from "@material-ui/icons";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}


const columns = [
  {
      title: "Name",
      render: rowData => <a href={'/Vaccines/' + rowData.vaccine_id}>{rowData.name}</a>,
      field: "name"
  },
  {
      title: "Mechanism",
      field: "mechanism"
  },
  {
      title: "Sponsers",
      field: "sponsers"
  },
  {
      title: "Trial Phase",
      field: "trial_phase"
  },
  {
      title: "Institutions",
      field: "institutions"
  },
  {
      title: "Approved Countries",
      field: "approved_countries"
  }
];

function Vaccines() {
  var [vaccines, setVaccines] = useState([]);

  // fetch country data and render on callback
  useEffect(() => {
    const url = "https://vaxtracker.me/api/vaccines";
    fetchJSON(url).then((data) => {
      setVaccines(data);
    });
  }, []);

  // generic async JSON fetching code
  async function fetchJSON(url) {
    var res = await fetch(url);
    return res.json();
  }

  return (
    <MaterialTable icons={tableIcons} title="Vaccines Data" data={vaccines} columns={columns} />
  );
}
export default Vaccines;
