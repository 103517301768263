import React, { Component } from 'react';
import "./About.css";
import { memberInfo } from "../../components/MemberInfo";
import InfoCards from '../../components/InfoCards';
import PostmanButton from '../../components/PostmanButton';

class About extends Component {
  state = {
    issues: [],
    commits: [],
    members: memberInfo,
  }

  componentDidMount() {
    fetch('https://gitlab.com/api/v4/projects/24677261/issues?per_page=100')
      .then(res => res.json())
      .then((data) => {
          this.setState({ ...this.state, issues: data });
          this.setMemberIssuesInfo();
      })
          .catch(console.log);
    fetch('https://gitlab.com/api/v4/projects/24677261/repository/commits?per_page=400')
      .then(res => res.json())
      .then((data) => {
          this.setState({ ...this.state, commits: data });
          this.setMemberCommitsInfo();
      })
          .catch(console.log);
  }

  setMemberIssuesInfo() {
    var d = {};
    for (let i = 0; i < memberInfo.length; i++) {
      d[this.state.members[i].gitlab] = i;
    }
    var issuesArr = new Array(5).fill(0);
    for (let j = 0; j < this.state.issues.length; j++) {
      let index = d[this.state.issues[j].author.username];
      issuesArr[index] += 1;
    }

    var members = this.state.members;
    for (let i = 0; i < issuesArr.length; i++) {
      members[i]["issues"] = issuesArr[i];
    }
    this.setState({
      ...this.state,
      members: members
    });
  }


  setMemberCommitsInfo() {
    var d = {};
    for (let i = 0; i < this.state.members.length; i++) {
      d[this.state.members[i].email] = i;
    }
    var commitArr = new Array(5).fill(0);

    for (let j = 0; j < this.state.commits.length; j++) {
      let index = d[this.state.commits[j].author_email];
      commitArr[index] += 1;
    }

    var members = this.state.members;
    for (let i = 0; i < commitArr.length; i++) {
      members[i]["commits"] = commitArr[i];
    }
    this.setState({
      ...this.state,
      members: members
    });
  }

  render() {
    return (
      <div>
        <center><h1>About Vax Tracker</h1></center>
        <h4 className="description">Vax Tracker is a website that allows users to find data about Covid-19 vaccinations quickly and easily. We have data on vaccinations around the world, clinical trials, and beliefs around Covid-19. </h4>
        <center><h1>Our Team</h1></center>
        <div className="row justify-content-center">
          <InfoCards members={this.state.members[0]} />
          <InfoCards members={this.state.members[1]} />
          <InfoCards members={this.state.members[2]} />
          <InfoCards members={this.state.members[3]} />
          <InfoCards members={this.state.members[4]} />
        </div>
        <div>Number of Commits: {this.state.commits.length}</div>
        <div>Number of Issues: {this.state.issues.length}</div>
        <div>Number of Tests: 0</div>
        <PostmanButton />
        <br></br>
        <p>Purpose: Gather Covid vaccine information and provide them to people</p><br />
        <p>Description: A website that utilizes data from multiple sources to provide the up-to-date information about Covid vaccine, vaccine allocation and beliefs</p><br />
        <p>Intended Users: General people who are willing to get knowledge of Covid vaccine information </p><br />
        <p>Explanation of the Interesting Result of Integrating Disparate Data: It's interesting and exciting to see the rate of vaccine allocation increases as time goes by.</p><br />
        <p>Description of Data Scraping: The data are mainly scraped from the following APIs with some additional data from the following supplementary link. </p><br />
        <p>Data APIs:</p>
        <a href="https://gisumd.github.io/COVID-19-API-Documentation/docs/home.html">Data for Covid related Belief 1</a><br />
        <a href="https://covidsurvey.mit.edu/api.html">Data for Covid related Belief 2</a><br />
        <a href="https://disease.sh/docs/">World Vaccine Allocation</a><br />
        <a href="https://clinicaltrials.gov/api/gui"> Clinical Trials </a><br /><br />
        <p>Supplementary Data Sources:</p>
        <a href="https://www.raps.org/news-and-articles/news-articles/2020/3/covid-19-vaccine-tracker">Data for vaccine information</a><br /><br />
        <p>Tools:</p>
        <p>Docker: virtual development environment</p><br />
        <p>GitLab: git repository</p><br />
        <p>React: frontend</p><br />
        <p>Bootstrap: CSS framework</p><br />
        <p>AWS: website hosting platform</p><br />
        <p>Postman: API design and testing </p><br />
        <p>MySQL: database </p><br />
        <a href="https://gitlab.com/cs373-team2/vax-tracker">Gitlab Link</a><br />
      </div>
    );
  }
}

export default About;


