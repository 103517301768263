import React from "react";
import "../pages/home/Home.css";

const Jumbotron = () => {
  return (
    <div className="container-fluid home-container">
      <h1 className="splash-header">VaxTracker</h1>
      <h3 className="splash-subtext">
        A modern RESTful COVID-19 vaccination API
      </h3>
    </div>
  );
};

export default Jumbotron;