import React from "react";
import { useEffect, useState } from "react";
import "./GlobalVacc.css";
import { Pagination } from "@material-ui/lab";
import Country_Grid from "./Country_Grid";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  pagination: {
    justifyContent: "center",
    display: "flex",
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export function thousands_separators(num) {
  if(num == undefined)
    return;
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
}

function GlobalVacc() {
  const CardsPerPage = 3;
  const styles = useStyles();
  var [countries, setCountries] = useState([]);
  var [currentData, setCurrentData] = useState([]);

  // fetch country data and render on callback
  useEffect(() => {
    const url = "https://vaxtracker.me/api/countries";
    fetchJSON(url).then((data) => {
      setCountries(data);
      setCurrentData(data.slice(0, CardsPerPage));
    });
  }, []);

  // generic async JSON fetching code
  async function fetchJSON(url) {
    var res = await fetch(url);
    return res.json();
  }

  // update current "data frame"
  function handleChangePage(pageNumber) {
    setCurrentData(countries.slice(pageNumber * CardsPerPage - CardsPerPage, pageNumber * CardsPerPage));
  }

  var numTotalPages = Math.ceil(countries.length / CardsPerPage);

  return (
    <div className="container">
      <h1>Global Vaccine Data</h1>
      <Country_Grid data={currentData}></Country_Grid>
      <div className={styles.pagination}>
        <Pagination
          count={numTotalPages}
          onChange={(_, page) => handleChangePage(page)}
          showFirstButton
          showLastButton
          color="primary"
        />
      </div>
    </div>
  );
}

export default GlobalVacc;
